import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import { FALLBACK_LANG } from "common/constants"
import { getUserLanguage } from "common/utils/lang"

import translationEN from "./locales/en/translation.json"
import translationES from "./locales/es/translation.json"
import translationsPT from "./locales/pt/translation.json"

type TTranslationKeys = typeof translationEN | typeof translationsPT | typeof translationES

type TTFunction = (key: keyof TTranslationKeys, opts?: Record<string, number | string>) => string

if (!i18n.isInitialized) {
    i18n.use(initReactI18next).init({
        fallbackLng: getUserLanguage ? getUserLanguage() : FALLBACK_LANG,
        returnEmptyString: false,
        resources: {
            en: {
                translation: translationEN
            },
            es: {
                translation: translationES
            },
            pt: {
                translation: translationsPT
            }
        },
        interpolation: {
            escapeValue: false
        },
        nsSeparator: false,
        keySeparator: false
    })
    i18n.isInitialized = true
}

export { i18n, type TTranslationKeys, type TTFunction }
